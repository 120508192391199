const simpleHeader = (fontSize: string, color: string, message: string) => {
    return <h1 className={"typewriter"} style={{ fontSize, color }}>{message}</h1>
}

const messages = {
    name: simpleHeader("100px", "white", "fucktoy"),
} as MessagesObject;



export const MessagePanel = (props: MessagePanelProps) => {
    const { message } = props;
    return <div>{messages[message]}</div>;
}

interface MessagePanelProps {
    message: string;
}

interface MessagesObject {
    [index: string]: JSX.Element;
}

