import React, { Children, useCallback } from "react";
import { useState } from "react";
import { FaderItem, FaderItemStates } from "./FaderItem";

export const Fader = (props: FaderProps) => {
    const { loop, children } = props;
    const [step, setStep] = useState(0);
    const initialStates = Children.map(children, () => "hidden") as FaderItemStates[];
    initialStates[0] = "visible";
    const [stepStates, setStepStates] = useState(initialStates);
    const [isUpdating, setIsUpdating] = useState(false);

    const nextStep = useCallback(() => {
        if (isUpdating) {
            return;
        }
        if (loop === false && step + 1 >= stepStates.length) {
            return;
        }
        setIsUpdating(true);
        const previousStep = step;
        const nextStep = step + 1 >= stepStates.length ? 0 : step + 1;
        const newStepStates = Children.map(children, () => "hidden") as FaderItemStates[];
        newStepStates[previousStep] = "hiding";
        setStepStates([...newStepStates]);
        setStep(nextStep);
        setTimeout(() => {
            const newStepStates = Children.map(children, () => "hidden") as FaderItemStates[];
            newStepStates[nextStep] = "appearing";
            setStepStates([...newStepStates]);
            setTimeout(() => {
                const newStepStates = Children.map(children, () => "hidden") as FaderItemStates[];
                newStepStates[nextStep] = "visible";
                setStepStates([...newStepStates]);
                setIsUpdating(false);
            }, 750);
        }, 750);
    }, [loop, step, isUpdating, setIsUpdating, setStepStates, setStep, stepStates.length, children]);

    if (stepStates.length === 0) {
        return null;
    }

    console.log([...stepStates]);
    return (<>
        {React.Children.map(children, (child, index) => (
            <FaderItem stepStates={stepStates} step={index} nextStep={nextStep} key={index}>{child}</FaderItem>
        ))}
    </>);
}
// export type FaderItemStates = "appearing" | "visible" | "hiding" | "hidden";
export interface FaderProps {
    loop: boolean;
    children: React.ReactNode | React.ReactNode[];
}