// import React from 'react';
import { Fader } from "../fader";
import { MessagePanel } from "./MessagePanel";

export const Fucktoy = () => {
    return (
        <Fader loop={false}>
            <MessagePanel message={"name"} />
        </Fader>
    )
}
