// import React from 'react';
// import logo from './logo.svg';
import './App.css';

import { Routes, Route } from "react-router-dom";
import { Home } from './components/home';
import { Susie } from './components/susie';
import { Fucktoy } from './components/fucktoy';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/susie" element={<Susie />} />
        <Route path="/fucktoy" element={<Fucktoy />} />
      </Routes>
    </div>
  );
}

export default App;

