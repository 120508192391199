const simpleHeader = (fontSize: string, color: string, message: string) => {
    return <h1 className={"typewriter"} style={{ fontSize, color }}>{message}</h1>
}

const messages = {
    name: simpleHeader("100px", "white", "Susie"),
    life: simpleHeader("70px", "white", "Life is Short"),
    death: simpleHeader("70px", "FireBrick", "Death is Forever"),
    survival: simpleHeader("60px", "FireBrick", "One chance for survival"),
    fucktoy: simpleHeader("70px", "white", "Become my fucktoy"),
    submission: simpleHeader("60px", "FireBrick", "Absolute Submission"),
    obedience: simpleHeader("60px", "FireBrick", "Utter Obedience"),
    less: simpleHeader("70px", "white", "Anything less..."),
    die: simpleHeader("60px", "FireBrick", "...and you will die"),
    rape: simpleHeader("70px", "FireBrick", "I will rape you"),
    yet_obey: simpleHeader("70px", "white", "Yet you must obey"),
    hurt: simpleHeader("70px", "FireBrick", "I will hurt you"),
    yet_submit: simpleHeader("70px", "white", "Yet you must submit"),
    come: simpleHeader("70px", "FireBrick", "Come to me, MY fucktoy"),
    disobedience: simpleHeader("50px", "FireBrick", "Disobedience brings death"),
    toys: simpleHeader("60px", "white", "Bring your sex toys"),
    clothes: simpleHeader("60px", "white", "Bring a change of clothes"),
    door: simpleHeader("60px", "white", "Be at my door at 8:00 pm"),
    my_fucktoy: simpleHeader("70px", "FireBrick", "MY fucktoy"),
} as MessagesObject;



export const MessagePanel = (props: MessagePanelProps) => {
    const { message } = props;
    return <div>{messages[message]}</div>;
}

interface MessagePanelProps {
    message: string;
}

interface MessagesObject {
    [index: string]: JSX.Element;
}

