// import React from 'react';
import { Fader } from "../fader";
import { MessagePanel } from "./MessagePanel";
import { SniperImagesPanel } from "./SniperImagesPanel";

export const Susie = () => {
    return (
        <Fader loop={false}>
            <MessagePanel message={"name"} />
            <SniperImagesPanel image={"AtDoor"} color={true} />
            <MessagePanel message={"life"} />
            <MessagePanel message={"death"} />
            <SniperImagesPanel image={"InCar"} color={true} />
            <MessagePanel message={"survival"} />
            <MessagePanel message={"fucktoy"} />
            <SniperImagesPanel image={"Leaving"} color={true} />
            <MessagePanel message={"submission"} />
            <MessagePanel message={"obedience"} />
            <MessagePanel message={"less"} />
            <SniperImagesPanel image={"OnBench"} color={true} />
            <MessagePanel message={"die"} />
            <MessagePanel message={"rape"} />
            <MessagePanel message={"yet_obey"} />
            <SniperImagesPanel image={"AtDoor"} color={false} />
            <MessagePanel message={"hurt"} />
            <MessagePanel message={"yet_submit"} />
            <SniperImagesPanel image={"InCar"} color={false} />
            <MessagePanel message={"come"} />
            <MessagePanel message={"disobedience"} />
            <MessagePanel message={"toys"} />
            <MessagePanel message={"clothes"} />
            <MessagePanel message={"door"} />
            <MessagePanel message={"my_fucktoy"} />
        </Fader>
    )
}
