import AtDoor from "./images/at-door-sniper-color.jpg";
import InCar from "./images/in-car-sniper-color.jpg";
import Leaving from "./images/leaving-sniper-color.jpg";
import OnBench from "./images/on-bench-sniper-color.jpg";
import AtDoorBW from "./images/at-door-sniper.jpg";
import InCarBW from "./images/in-car-sniper.jpg";
import LeavingBW from "./images/leaving-sniper.jpg";
import OnBenchBW from "./images/on-bench-sniper.jpg";

const colorImages = {
    AtDoor,
    InCar,
    Leaving,
    OnBench,
};

const imagesBW = {
    AtDoor: AtDoorBW,
    InCar: InCarBW,
    Leaving: LeavingBW,
    OnBench: OnBenchBW,
};

export const SniperImagesPanel = (props: SniperImagesPanelProps) => {
    const { image, color } = props;
    return (
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
            <img src={color ? colorImages[image] : imagesBW[image]} alt={"siper"} style={{ maxWidth: "100%" }} />
        </div>
    );
}

interface SniperImagesPanelProps {
    image: "AtDoor" | "InCar" | "Leaving" | "OnBench";
    color: boolean;
}