const styles = {
    appearing: {
        opacity: 0,
        transition: "all 0.75s ease",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
        cursor: "pointer",
    },
    visible: {
        opacity: 1,
        transition: "all 0.75s ease",
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
        cursor: "pointer",
    },
    hiding: {
        opacity: 0,
        transition: "all 0.75s ease",
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
        cursor: "pointer",
    },
    hidden: {
        opacity: 0,
        display: "none",
        transition: "all 0.75s ease",
        cursor: "pointer",
    },
};

export const FaderItem = (props: FaderItemProps) => {
    const { step, nextStep, children, stepStates } = props;
    const state = step < stepStates.length ? stepStates[step] : "hidden";
    if (step > stepStates.length) {
        return null;
    }
    return (<div style={styles[state] as React.CSSProperties} onClick={nextStep}>{children}</div>);
}


export type FaderItemStates = "appearing" | "visible" | "hiding" | "hidden";

export interface FaderItemProps {
    nextStep: () => void;
    stepStates: FaderItemStates[];
    step: number;
    children: React.ReactNode;
}
